<template>
  <ion-page>
    <top-header page-title="Despre aplicatie"></top-header>
    <ion-content>
      <ion-list>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-img src="assets/img/logo_50_50.png"></ion-img>
              </ion-col>
              <ion-col size="10">
                <ion-row>
                  <ion-col size="">Nume aplicație</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>Distracție Online</ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-icon class="ion-align-self-center" :icon="informationCircleOutline" size="large"></ion-icon>
              </ion-col>
              <ion-col size="10">
                <ion-row>
                  <ion-col>Versiune Build</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>{{ appVersion }}</ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-icon :icon="accessibilityOutline" size="large"></ion-icon>
              </ion-col>
              <ion-col size="10">
                <ion-row>
                  <ion-col>Project creat de</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>Alex Voina</ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-icon :icon="starOutline" size="large"></ion-icon>
              </ion-col>
              <ion-col size="10">
                <ion-row>
                  <ion-col>Evaluează aplicația</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col><a :href="this.$store.getters.getMarketUrl" target="_blank">Evaluază aplicația dacă ți-a plăcut!</a></ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonIcon
} from '@ionic/vue';
import {
  informationCircleOutline,
  accessibilityOutline,
  starOutline
} from "ionicons/icons";
import TopHeader from "../components/menu/TopHeader";
import Package from "../../package.json";

export default {
  components: {
    IonPage,
    TopHeader,
    IonContent,
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonIcon
  },
  data() {
    return {
      appVersion: Package.version,
    }
  },
  setup() {
    return {
      informationCircleOutline,
      accessibilityOutline,
      starOutline,
    }
  },
}
</script>

<style scoped>
ion-content ion-list ion-item ion-grid ion-row ion-col:first-child {
  display: flex;
  align-items: center;
}
</style>